import React from 'react';

import { PromopanelResponse } from '../../types/entities';

import PromopanelComponent from '@helsenorge/core-cms/promopanel';
import { PromoBlock } from '@helsenorge/core-cms/types/entities';
import { get } from '@helsenorge/framework-utils/cms-content-api-service';
import { useSetUserLanguageEvent } from '@helsenorge/framework-utils/hn-language';
import { CmsBlocksEvents, WebComponents } from '@helsenorge/framework-utils/web-component/constants';
import { SubscribeContext } from '@helsenorge/framework-utils/web-component/context';
import { HNeventIsMicroFrontendMounted } from '@helsenorge/framework-utils/web-component/events';
import { WebcompProps } from '@helsenorge/framework-utils/web-component/register';

interface Props {
  externalid?: string | boolean;
}

const Promopanel: React.FC<Props & WebcompProps> = ({ externalid }: Props) => {
  const [blocks, setBlocks] = React.useState<Array<PromoBlock>>([]);
  const [hide, setHide] = React.useState(true);
  const subscribe = React.useContext(SubscribeContext);

  const getPromopanel = (): void => {
    get<PromopanelResponse>(`promopanel${externalid ? `/${externalid}` : ''}`)
      .then(response => {
        if (response?.blocks) {
          setBlocks(response.blocks);
        }
      })
      .catch(() => {
        // Dersom det skjer en feil, logges dette av cms-content-api-service
      });
  };

  React.useEffect(() => {
    subscribe(CmsBlocksEvents.setHiddenPromopanel, (event: CustomEvent) => {
      const hiddenPromopanel = event.detail.hiddenPromopanel;
      setHide(hiddenPromopanel);
    });

    HNeventIsMicroFrontendMounted(WebComponents.Promopanel);

    getPromopanel();
  }, []);

  useSetUserLanguageEvent(() => getPromopanel());

  return !hide && !!blocks && blocks.length > 0 ? <PromopanelComponent promoblocks={blocks} /> : null;
};

export default Promopanel;
