import { WebComponents } from '@helsenorge/framework-utils/web-component/constants';
import registerWebComp from '@helsenorge/framework-utils/web-component/register';

import Promopanel from './components/promopanel';

async function enableMocking(): Promise<ServiceWorkerRegistration | void> {
  if (import.meta.env.MODE !== 'mocks') {
    return;
  }

  const { worker } = await import('./mocks/browser');

  return worker.start({
    onUnhandledRequest: 'bypass',
  });
}

enableMocking().then(() => {
  registerWebComp(Promopanel, WebComponents.Promopanel, { events: true }, 'hn-webcomp-cms-block-promopanel-template');
});
